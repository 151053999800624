.users_table {
    display: flex;
    flex-direction: column;
    background: #fff;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
    width: 100%;
    margin: 20px 0 20px 0;
    justify-content: center;
    &__content {
        margin: 25px;
    }
}
.checkedBox {
    svg {
        color: #00a5ad;
    }
}
.no_users_yet {
    padding: 15px;
}

.info_row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
}

.line_break {
    margin-top: 25px;
    border-top: solid 1px #bbc9d9;
}

li {
    padding-top: 10px;
}

.role {
    padding-top: 16px;
    font-size: 14px;
}
