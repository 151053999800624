.manage_users_header {
  height: 84px;
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
h1 {
  margin: 5px 0;
  font-size: 30px;
}
.icon_container {
  display: flex;
  align-items: center;
  margin-right: 25px;
svg {
  color: #435970;
}
}
}


.users_info_box {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 25px;
  background: #fff;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);

&__info {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   font-size: 22px;
   margin-right: 25px;
 }

&__expiration_info {
   justify-content: flex-end;
   border-left: solid 2px #435970;
   display: flex;
   flex-flow: column;
   padding-left: 25px;
 }
&__assessment,
&__name {
   padding-right: 25px;
   display: flex;
   flex-flow: column;
   justify-content: space-evenly;
   margin-bottom: 4px;
 }

&__add_user {
   position: absolute;
   right: 25px;
 }
}
