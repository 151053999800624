.target_group_view {
    padding: 40px;
}

.target_group_info_box {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding: 25px;
    gap: 24px;
    background: #fff;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
    @media only screen and (max-width: 1350px) {
        height: 176px;
    }
    &__company_name {
        margin-bottom: 10px;
        &__color {
            color: $tealblue;
        }
    }
    &__wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        max-width: 45%;
        gap: 20px;
        @media only screen and (max-width: 1560px) {
            max-width: 40%;
        }
        @media only screen and (max-width: 1470px) {
            max-width: 68%;
        }
    }
    &__info {
        padding-right: 16px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-size: 22px;
        border-right: solid 2px #435970;
        min-width: 145px;
    }
    &__public_link{
        position: relative;
        min-width: 190px;
        border-radius: 4px;
    }
    &__copy_tooltip {
        position: absolute;
        top: 37px;
        left: 25%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 94px;
        height: 27px;
        padding: 0 10px;
        gap: 6px;
        font-weight: 500;
        font-size: 11px;
        line-height: 27px;
        background: rgb(187, 201, 217);
        border-radius: 4px;
        transition: visibility 0.5s, color 0.5s, background-color 0.5s, height 0.5s,
        padding 0.5s ease-in-out;
        p{
            color: white;
        }
    }
    &__assessment,
    &__name {
        display: flex;
        flex-flow: column;
        justify-content: space-evenly;
        margin-bottom: 4px;
    }
    &__scan_name {
        margin: 40px 0 8px 0;
    }

    &__add_participant {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 24px;
        @media only screen and (max-width: 1470px) {
            flex-direction: column;
            gap: 16px;
            align-items: flex-start;
        }
    }
}

.number_of_candidates {
    display: inline-block;
    &__text {
        color: $tealblue;
        font-size: 16px;
        font-weight: bold;
        line-height: 1.75;
    }

    &__icon {
        color: $tealblue;
        display: inline-block;
        margin-right: 5px;
    }
}

.add_candidates {
    display: block;
    background: #fff;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
    width: 100%;
    min-height: 400px;
    margin: 20px 0;

    &__close {
        cursor: pointer;
        opacity: 0.5;

        &:hover {
            opacity: 1;
        }
    }
}

.loader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    span {
        margin-top: 10px;
    }
}

.delete_box {
    margin: 32px 0;

    h3 {
        font-size: 20px;
        color: #435970;
        font-weight: bold;
    }

    &__description {
        padding: 16px 0;
        font-size: 1rem;
        font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
        font-weight: 400;
        line-height: 1.5;
        letter-spacing: 0.00938em;
        color: #435970;
    }
}
