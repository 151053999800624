.baseline-bar {
    padding: 24px 8px;
    width: 60%;
    height: 150px;
    font-size: 16px;
}

.baseline-bar .value {
    float: left;
    text-align: center;
    position: absolute;
    z-index: 1;
}
.baseline-bar .value span{
    color: white;
}
.baseline-bar .bars {
    position: relative;
}
.baseline-bar .bars .bar {
    float: left;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.baseline-bar .legends {
    padding-bottom: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 32px;
}

.baseline-bar .legends .legend {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4px;
}

.baseline-bar .legends .legend .square {
    width: 20px;
    height: 20px;
    border: 1px solid transparent;
}

.baseline-bar .legends .legend .label {
    color: #40596F;
}