.card {
  background: #fff;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 14px;
  position: relative;
  padding: 20px;
  min-height: 140px;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & h1 {
    font-size: 18px;
    margin-bottom: 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  & span {
    font-size: 14px;
  }
  transition: all 0.5s ease-in-out;

  &:hover {
    transform: translateY(-5px);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05), 0 3px 6px rgba(0, 0, 0, 0.05);
  }

  &__header {
    display: flex;
    flex-direction: column;
    width: 100%;

    h1 {
      font-size: 18px;
    }

    h2 {
      font-size: 16px;
      color: $tealblue;
      font-weight: bold;
      line-height: 1.17;
    }
  }
  &__badges {
    display: flex;
    flex-flow: row wrap;
  }
  &__badge {
    display: inline-block;
    margin-top: 10px;
    margin-right: 20px;
    &:last-child {
      margin-right: 0;
    }
  }
}
