.steps {
    position: relative;
    padding: 24px 4px;
    color: rgb(187, 201, 217);
    height: 164px;
    width: 100%;
    counter-reset: step;
    display: flex;
    align-items: center;
    li {
        list-style-type: none;
        font-size: 16px;
        text-align: center;
        color: rgb(187, 201, 217);
        width: 25%;
        position: relative;
        float: left;
    }

    li:before {
        display: block;
        content: counter(step);
        counter-increment: step;
        width: 40px;
        height: 40px;
        background-color: #00a5ac;
        line-height: 40px;
        border-radius: 40px;
        font-size: 16px;
        color: #fff;
        text-align: center;
        font-weight: 700;
        margin: 0 auto 8px auto;
    }
    &__exist:before {
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0,0,256,256' width='26px' height='26px' fill-rule='nonzero'%3E%3Cg fill='%23ffffff' fill-rule='nonzero' stroke='none' stroke-width='1' stroke-linecap='butt' stroke-linejoin='miter' stroke-miterlimit='10' stroke-dasharray='' stroke-dashoffset='0' font-family='none' font-weight='none' font-size='none' text-anchor='none' style='mix-blend-mode: normal'%3E%3Cg transform='scale(8.53333,8.53333)'%3E%3Cpath d='M26.98047,5.99023c-0.2598,0.00774 -0.50638,0.11632 -0.6875,0.30273l-15.29297,15.29297l-6.29297,-6.29297c-0.25082,-0.26124 -0.62327,-0.36647 -0.97371,-0.27511c-0.35044,0.09136 -0.62411,0.36503 -0.71547,0.71547c-0.09136,0.35044 0.01388,0.72289 0.27511,0.97371l7,7c0.39053,0.39037 1.02353,0.39037 1.41406,0l16,-16c0.29576,-0.28749 0.38469,-0.72707 0.22393,-1.10691c-0.16075,-0.37985 -0.53821,-0.62204 -0.9505,-0.60988z'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E") counter(step, none) !important;
        display: block;
        width: 40px;
        height: 40px;
        counter-increment: step;
        background-color: #00a5ac;
        padding-top: 6px;
        line-height: 40px;
        border-radius: 40px;
        font-size: 16px;
        color: #fff !important;
        text-align: center;
        font-weight: 700;
        margin: 0 auto 8px auto;
    }

    li ~ li:after {
        left: -32%;
        content: '';
        position: absolute;
        top: 29px;
        width: 66%;
        border-top: 2px solid #00a5ac;
    }

    li.active:after {
        border-top: 2px solid #00a5ac;
    }

    li.active {
        color: #00a5ac;
    }

    li:not(.active ~ li, .active) {
        color: #00a5ac;
    }

    li.active ~ li:before,
    li.active ~ li:after {
        border-top: 2px solid rgb(187, 201, 217);
        background-color: rgb(187, 201, 217);
    }
}
