.dialog {
&__content {
   border-bottom: solid 1px #bbc9d9;

&__list {
   padding: 10px 0;
 }
ul {
  padding: 0;
li {
  list-style: none;
  margin-left: 35px;
  font-style: normal;
}
}
i {
  font-size: 0.85rem;
}
table {
  padding: 15px 0;

td {
  padding: 0 50px 0 0;
}
}
}

&__candidates_cannot_receive_email {
   border: solid 1px #bbc9d9;
   background-color: #f5f6fa;
   padding: 15px;
   margin-top: 20px;
   padding-bottom: 0;
   font-style: italic;

ul {
  padding: 0;
li {
  list-style: disc;
  margin-left: 20px;
}
}

table {
  padding: 15px 0;
  font-style: normal;

td {
  padding: 0 50px 0 0;
  font-style: normal;
}
}
}
&__logoPreview {
   max-height: 150px;
   width: auto;
  margin-left: 30px;
 }
}
