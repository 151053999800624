.add_users_container {
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  width: 100%;
  margin: 20px 0;
  justify-content: center;
  font-weight: bold;
  font-size: 14px;
  color: #435970;

  &__content {
    margin: 25px;
  }
}

.form {
  display: grid;
  grid-template-columns: 12% 88%;

  &__item {
    padding: 10px;
    margin: 20px;

    &__label {
      display: flex;
      align-items: center;
    }

    &__input_box {
      display: flex;
      align-items: center;
      input,
      select {
        background-color: #f5f6fa;
        width: 40%;
        padding: 12px 12px;
        margin: 10px 0 10px 0;
        border: 0;
      }
      textarea:focus,
      input:focus,
      select:focus {
        outline-color: #19a6ad;
      }
    }

    &__permissions_box {
      background-color: #f5f6fa;
      width: 70%;
      padding: 12px 12px;
      margin: 10px 0 10px 0;
      font-weight: normal;

      &__user_permissions {
        display: grid;
        grid-template-columns: auto auto auto;

        &__override_permission {
          grid-column-start: 1;
          grid-column-end: 4;
          border-bottom: 1px solid #445870;
        }
      }

      &__target_group_permissions {
        display: grid;
        grid-template-columns: auto auto;

        &__all_target_groups_permission {
          grid-column-start: 1;
          grid-column-end: 3;
          border-bottom: 1px solid #445870;
        }
      }
    }
  }
}

.footer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  .button-right {
    margin-right: 20px;
  }
  .button-left {
    margin-left: 20px;
  }
}

.checkedBox {
  svg {
    color: #00a5ad;
  }

  &__disabled {
    svg {
      color: #bfc6c6;
    }
  }
}
