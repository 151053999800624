@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,700&display=swap');

/* Fonts/font sizes
H1 = Roboto Bold, 30px
H2 = Roboto Bold, 22px
Text regular = Roboto Regular, 18px
Text bold = Roboto Bold, 18px
Buttons text = Roboto Bold, 20px
Target group block text = Roboto Bold, 18px */

html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 16px;
}

body {
  background: #f5f6fa;
  width: 100%;
  height: 100%;
  font-family: 'Roboto', sans-serif;
}

:global(#root) {
  display: block;
  width: 100%;
  height: 100%;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

body p,
span,
b {
  color: #425A70;
}

a {
  color: #1070CA;
}

h1,
h2,
h3 {
  color: #435970;
  font-weight: bold;
}

ol,
ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

h1 {
  font-size: 30px;
}

h2 {
  font-size: 22px;
}

h3 {
  font-size: 18px;
}
