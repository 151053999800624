.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.emailContainer {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-bottom: 8px;
}

.emailInput {
    margin: 0;
}
.errorText {
    font-size: 12px;
    color: #00a5ac;
    padding: 0 0 8px 4px;
    text-align: left;
}
.addButton,
.removeButton {
    margin: 0 !important;
    font-size: 16px !important;
    border-radius: 50% !important;
    color: white !important;
    background-color: #00a5ac !important;
    width: 22px !important;
    min-width: 22px !important;
    height: 22px !important;
    padding: 0 !important;
    font-weight: bold !important;
    &:hover {
        border: 1px solid #00a5ac !important;
        background-color: white !important;
        color: #00a5ac !important;
    }
    &:disabled {
        color: white !important;
        background-color: lightgray !important;
    }
}
