.topbar {
    z-index: 99;
    background: #fff;
    box-shadow: 0 3px 14px 0 #2a577e33;
    grid-area: topbar;
    position: sticky;
    top: 0;

    &__wrapper {
        width: 100%;
        padding: 1em 2.5em;
        display: grid;
        grid-gap: 10px;
         grid-template-columns: 4fr 1fr 170px;
         grid-template-rows: 1fr 20px;
         grid-template-areas:
            'title download lang'
            'group download lang';

        & > * {
            margin-top: auto;
            margin-bottom: auto;
        }
        &TitleGrid {
            grid-area: title;
            margin-bottom: 0;
        }
        &DownloadGrid {
            grid-area: download;

            & > * {
                margin-right: 0;
                margin-left: auto;
            }
        }
        &LanguageSwitchGrid {
            display: flex;
            grid-area: lang;
            justify-content: flex-end;
            max-width: 180px;
        }
        &GroupGrid {
            grid-area: group;

            &__group_name {
                color: #40596f94;
            }
        }
    }

    &__report_title {
        margin-bottom: 50px;
        color: #40596f;
        font-size: 32px;
        font-weight: 700;
        //transform: translate(-50%, -50%);
    }

    ul {
        display: flex;
        flex-flow: row nowrap;
        width: 100%;
        align-items: center; /* vertical alignment */
        justify-content: space-between;
        margin-left: calc(10% + 13px);
        margin-right: calc(10% + 13px);

        li {
            list-style-type: none;
        }
    }

    &User_menu {
        margin-right: 15px;
    }
}
