.layout {
  display: flex;
  width: 100%;
  flex-flow: row nowrap;
}

.content {
  flex: 1;
  height: 100%;
  margin: 80px 10%;
  padding: 25px 13px;
}
