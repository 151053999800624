.target_group_settings {
  &__configuration_tab {
     color: $tealblue!important;
     font-weight: bold;
     background-color: #eff8ff!important;
  }

  &__pane {
    &__close_icon {
      display: block;
      position: absolute;
      right: 16px;
      top: 12px;
    }

    &__close_icon:hover {
      cursor: pointer;
      color: #666666;
    }

    &__configuration {
      font-size: 14px;

      table {
        width: 100%;
        border-collapse: collapse;
        table-layout: fixed;

        .setting_key {
          width: auto;
        }
        .setting_value {
          width: 25%;
          min-width: 185px;
        }

        tr {
          border-width: 0 0 1px 0;
          border-color: #cccccc;
          border-style: solid;
        }

        td {
          padding: 13px 0;
        }

        td:first-of-type {
          text-align: left;
        }
        td:last-of-type {
          text-align: right;
        }

        tr:first-of-type {
          td {
            padding-top: 0;
          }
        }
        tr:last-of-type {
          border: none;
          td {
            padding-bottom: 0;
          }
        }
      }

      &__notifications_table {
        tr:nth-of-type(1) {
          border: none;
        }
        tr:nth-of-type(3) {
          border: none;
        }
      }

      &__config_label {
        font-size: 1.1em;
        color: #363b40;
      }
      p {
        font-weight: lighter;
        margin-top: 6px;
        color: #445870;
      }

      &__bold_teal_text {
        color: $tealblue;
        font-size: 0.9em;
        font-weight: bold;
        font-style: italic;
        margin-top: 6px !important;
      }

      &__description_title {
        color: $tealblue;
        font-weight: normal;
        font-size: 1.1em;
        margin-right: 5px;
      }
    }
  }
}

.link {
  color: $tealblue;
}

.switch {
  float: right;

  input:checked + div {
    background-color: $tealblue;
  }
  input:checked:hover + div,
  input:not([disabled]):checked:active + div {
    background-color: $tealblue;
    transition: 0.3s all ease-in;
    opacity: 0.7;
  }
}

.submit_wrap {
  padding: 20px 0;
  float: right;
  display: flex;
}

.save_result {
  padding-right: 15px;
  color: $tealblue;
  margin-top: auto;
  margin-bottom: auto;
}

.tab_list_icon {
  margin-right: 5px;
}

.email_verification{
  list-style-type: disc;
  list-style-position: inside;
  color: #293544;
  font-size: 0.9em;

  li {
    padding-top: 5px;
    font-weight: lighter;
  }
}
