.button {
  display: flex;
  height: 35px;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  border-radius: 9px;
  padding: 10px;
  span {
    font-weight: bold;
    font-size: 16px;
  }
  opacity: 1;

  transition: 0.3s all ease-in-out;

  &:hover {
    transition: 0.3s all ease-in;
    opacity: 0.7;
  }
}
