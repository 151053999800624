.footer {
    display: flex;
    flex-direction: column;
    gap: 24px;
}
.footer_with_dialog_buttons {
    margin: 0 0 20px 0;
    padding: 0 24px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    .button_wrapper{
        display: flex;
        flex-flow: row nowrap;
        gap: 24px;
    }
    .button {
        padding-left: 25px;
    }
}
.pages_wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    gap: 16px;
    margin: 0 25px;
    background-color: #f5f6fa;
    .page {
        cursor: pointer;
        color: white;
        width: 40px;
        border-radius: 24px;
        border: 1px solid #8f95b2;
        background-color: #8f95b2;
        font-weight: bold;
        text-align: center;
        &:hover {
            opacity: 0.5;
        }
        &__active_page {
            color: white;
            font-weight: bold;
            background-color: #00a5ac;
            width: 40px;
            border-radius: 24px;
            border: 2px solid #00a5ac;
            text-align: center;
        }
    }
}
.pages_ellipsis {
    font-weight: bold;
    font-size: 20px;
    align-self: baseline;
}
