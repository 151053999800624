.center_vertically {
    vertical-align: middle;
}

.status_wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    color: white;
    border-radius: 8px;
}
.user_activity {
    background: #e7f8f8;
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 14px;
    padding: 32px 16px;
    position: relative;
    &__title {
        font-size: 16px;
        font-weight: bold;
        padding-bottom: 10px;
    }

    &__error {
        display: flex;
        justify-content: center;
        align-items: center;
        span {
            font-size: 16px;
            color: #e96b76;
        }
    }

    &__loading {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-flow: row nowrap;
        height: 56px;
        gap: 8px;
        padding-inline-start: 24px;
        span {
            font-size: 14px;
            font-weight: bold;
        }
    }

    .section {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        margin-right: 30px;
        flex-grow: 1;
        .button {
            padding-bottom: 15px;
            align-self: flex-end;
        }
    }
    .half_width_section {
    display: flex;
    flex-direction: column;
        justify-content: space-between;
    width: 30%;
  }

    .full_width_section {
        flex-grow: 4;
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        margin-right: 30px;
        margin-top: 10px;
        margin-bottom: 20px;
    }
    .buttons_list {
        display: flex;
        flex-flow: row nowrap;
        .button {
            margin-left: 20px;
            &:first-child {
                margin-left: 0;
            }
        }
    }

    .files_info {
        display: flex;
        flex-direction: column;
        padding: 0 20px;
        .item {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            margin: 3px 10px;
            .highlight {
                font-weight: bold;
                margin-right: 20px;
            }
        }
    }
    .info_block {
        display: flex;
        flex-flow: column;
        gap: 12px;
        width: 80%;
        margin-top: 14px;

        .item {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;

            .highlight {
                font-weight: normal;
                line-height: 18px;
            }
        }
    }
}

.info_modal{
    cursor: pointer;
    &:hover {
        opacity: 0.6;
    }
}
.no_scroll {
    overflow: hidden;
    height: 100%;
}
.register_info{
    font-size: 12px;
    color: rgba(184, 184, 184, 1);
}
.checkedBox {
    svg {
        color: #00a5ad;
    }
}

.icon {
    &__success {
        color: #00a5ad;
        &:hover {
            color: #435970;
        }
    }
    &__error {
        color: #fff;
        background-color: #e60019;
        border-radius: 50%;
    }
}

.line_break {
    margin-top: 25px;
    border-top: solid 1px #bbc9d9;
}
.status {
    display: flex;
    justify-content: center;
    align-items: center;
    &__green {
        display: block;
        width: 9px;
        height: 9px;
        border-radius: 9px;
        margin: 0 10px;
        background: #7ed3ac;
    }
    &__gray {
        display: block;
        width: 9px;
        height: 9px;
        border-radius: 9px;
        margin: 0 10px;
        background: #7ed3ac;
    }
    &__red {
        display: block;
        width: 9px;
        height: 9px;
        border-radius: 9px;
        margin: 0 10px;
        background: #e96b76;
    }
    &__yellow {
        display: block;
        width: 9px;
        height: 9px;
        border-radius: 9px;
        margin: 0 10px;
        background: #fbe7a1;
    }
}

.result_notification{
    &__green{
        background: rgba(26, 162, 119, 1);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding-bottom: 0;
        padding-top: 0;
        height:40px;
        color: white;
        font-size: 14px;
        font-weight: 400;
        padding-left: 16px;
        line-height: 20px;
    }
    &__gray{
        background: rgb(187, 201, 217);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding-bottom: 0;
        padding-top: 0;
        height:40px;
        color: white;
        font-size: 14px;
        font-weight: 400;
        padding-left: 16px;
        line-height: 20px;
    }
    &__blue{
        background: rgba(95, 145, 204, 1);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding-bottom: 0;
        padding-top: 0;
        height:40px;
        color: white;
        font-size: 14px;
        font-weight: 400;
        padding-left: 16px;
        line-height: 20px;
    }
    &__oranje{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        background: #F6510E;
        height:40px;

        p{
            color: white;
            font-size: 14px;
            font-weight: 400;
            padding: 0 16px;
            line-height: 20px;
            a{
                font-size: 15px;
                color: white;
                text-decoration: underline;
                font-weight: bold;
                &:hover{
                    opacity: 0.6;
                }
            }
        }
    }
}

.no_candidates_yet {
    padding: 15px;
}

.target_group_table {
    display: flex;
    flex-direction: column;
    background: #fff;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
    width: 100%;
    margin: 20px 0 40px 0;
    justify-content: center;
    &__content {
        margin: 25px 25px 0 25px;
    }
}

/* .skeleton_container {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  position: relative;
} */
