.scan_template_view {
  padding: 40px;
}

.scan_template_info_box {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 25px 0;

  &__company_name {
    margin-bottom: 10px;
    color: $tealblue;
  }

  &__info {
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 22px;
    margin-right: 25px;
  }

  &__name {
    display: flex;
    flex-flow: column;
    justify-content: space-evenly;
    margin-bottom: 4px;
  }

  &__add_target_group {
  display: flex;
  flex-direction: row;
    position: absolute;
    right: 25px;
.button {
  padding-left: 25px;
}
  }
}

.number_of_scan_template {
  display: inline-block;
  &__text {
    color: $tealblue;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.75;
  }

  &__icon {
    color: $tealblue;
    display: inline-block;
    margin-right: 5px;
  }
}

.no_company_error{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 60px;

  h1 {
    color: rgb(67, 89, 112);
    margin: 10px 0;
    font-weight: bold;
    font-size: 1.5em;
    line-height: 1.5em;
  }

  h3 {
    color: #8da3b9;
    font-weight: normal;
    margin: 0;
    text-align: center;
    font-size: 1em;
    line-height: 1.5em;
  }
}
