.footer {
    display: flex;
    flex-direction: column;
    gap: 24px;
}
.footer_with_dialog_buttons {
    margin: 0 0 20px 0;
    padding: 0 24px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;
    .button {
        padding-left: 25px;
    }
}