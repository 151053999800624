body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  scrollbar-width: thin; /* "auto" or "thin" */
  scrollbar-color: rgba(64, 89, 111, 0.8) transparent; /* Thumb and track color */
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 12px;
}

::-webkit-scrollbar-thumb {
  background: rgba(64, 89, 111, 0.8);
  border-radius: 12px;
  border: 3px solid rgba(64, 89, 111, 1);
  opacity: 0.5;
}