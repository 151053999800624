.companies_view {
  padding: 40px;
}

.companies_info_box {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 25px;
  background: #fff;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);

  &__info {
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 22px;
    margin-right: 25px;
    border-right: solid 2px #435970;
  }

  &__name {
    display: flex;
    flex-flow: column;
    justify-content: space-evenly;
    margin-bottom: 4px;
  }

  &__add_company {
    position: absolute;
    right: 25px;
  }
}

.number_of_companies {
  display: inline-block;
  &__text {
    color: $tealblue;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.75;
  }

  &__icon {
    color: $tealblue;
    display: inline-block;
    margin-right: 5px;
  }
}

.card_list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 25px 0;
  row-gap: 25px;
  column-gap: 25px;
  a {
    text-decoration: none;
  }
}
