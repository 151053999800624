.topbar {
    z-index: 10;
    display: flex;
    height: 100px;
    width: 100%;
    background: #fff;
    box-shadow: 2px 0 67px -40px rgba(0, 0, 0, 0.58);
    position: fixed;

    ul {
        display: flex;
        flex-flow: row nowrap;
        width: 100%;
        align-items: center; /* vertical alignment */
        justify-content: space-between;
        margin-left: calc(10% + 13px);
        margin-right: calc(10% + 13px);

        li {
            list-style-type: none;
        }
    }

    &Neurolytics_logo {
        flex-grow: 2;
        img {
            width: 167px;
        }
        :hover {
            opacity: 0.9;
        }
    }
    &Home_icon {
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-right: -50%;
        transform: translate(-50%, -50%);
    }

    &User_menu {
        margin-right: 15px;
    }
    &User_nav {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
    }
    &User_link {
        color: #435970;
        &:hover {
            color: #0ba6ac;
            padding-bottom: 4px;
            border-bottom: 1px solid #0ba6ac;
        }
    }
    &User_active {
        color: #0ba6ac;
        padding-bottom: 4px;
        border-bottom: 1px solid #0ba6ac;
    }
    &Left {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        gap: 24px;
    }
}
