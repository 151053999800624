a {
  text-decoration: none !important;
}
.container {
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 25px;
  margin: 20px 0;
  .table {
    display: flex;
    flex-direction: column;
    span {
      font-weight: bold;
      font-size: 14px;
      padding: 5px 28px;
    }
    .header_row {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      border-top: 0.5px solid #bbc9d9;
      border-right: 0.5px solid #bbc9d9;
      border-left: 0.5px solid #bbc9d9;
      background-color: #f5f6fa;
    }
    .row {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
    }

    .add_row {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      padding: 10px 0;
      border-bottom: 0.5px solid #bbc9d9;
      background-color: #f5f6fa;

      svg {
        color: #bbc9d9;
      }
      &:hover {
        background: #ededed;
      }
    }

    .cell {
      display: flex;
      align-items: center;
      flex: 1;
      border-bottom: 0.5px solid #bbc9d9;
      padding: 5px;

      input {
        box-shadow: none;
        padding: 0 30px;
        &:focus {
          background: #ededed;
        }
      }
      &__invalid {
        display: flex;
        align-items: center;
        flex: 1;
        border-bottom: 0.5px solid #bbc9d9;
        padding: 5px;

        input {
          box-shadow: none;
          padding: 0 30px;
          border-color: #d14343;
          transition: 1s all ease-in-out;
          &:focus {
            background: #ededed;
          }
        }
      }
      &__valid {
        display: flex;
        align-items: center;
        flex: 1;
        border-bottom: 0.5px solid #bbc9d9;
        padding: 5px;

        input {
          box-shadow: none;
          padding: 0 30px;
          border-color: #00a5ad;
          transition: 1s all ease-in-out;
          &:focus {
            background: #ededed;
          }
        }
      }
    }
  }

  .footer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: 20px;
    .button-right {
      margin-right: 20px;
    }
    .button-left {
      margin-left: 20px;
    }
  }
  input[type='file'] {
    position: absolute;
    z-index: -1;
    top: 10px;
    left: 8px;
    font-size: 17px;
    text-indent: -9999px;
  }
}

.csv_buttons {
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 20px;

  .button {
    padding-right: 15px;
  }

  input[type='file'] {
    position: absolute;
    z-index: -1;
    top: 10px;
    left: 8px;
    font-size: 17px;
    text-indent: -9999px;
  }
}

.dialog {
  &__content {
    border-bottom: solid 1px #bbc9d9;
    margin: 10px;

    ul {
      padding: 0;
      li {
        list-style: disc;
        margin-left: 40px;
      }
    }

    table {
      padding: 15px 0;

      td {
        padding: 0 50px 0 0;
      }
    }
  }

  &__list {
    padding: 10px 0;
  }

  &__candidates_cannot_receive_email {
    border: solid 1px #bbc9d9;
    background-color: #f5f6fa;
    padding: 15px;
    margin: 10px;
    margin-top: 20px;
    padding-bottom: 0;
    font-style: italic;
    ul {
      padding-left: 5px;
    }
    li {
      font-style: normal;
    }
  }
}
