.badge {
    display: inline-block;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: left;
    font-size: 16px;
    padding: 4px 16px;
    border-radius: 100px;
    &__main {
        background-color: $tealblue;
        color: #ffffff;
    }
    &__secondary {
        background-color: #435970;
        color: #ffffff;
    }
}
