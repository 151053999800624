.card {
    background: #fff;
    box-shadow: 0 4px 16px 0 #43597033;
    border-radius: 12px;
    position: relative;
    padding: 20px;
    min-height: 140px;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & h1 {
        font-size: 18px;
        margin-bottom: 5px;
    }
    & span {
        font-size: 13px;
    }
    transition: all 0.5s ease-in-out;

    &:hover {
        transform: translateY(-5px);
        transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05), 0 3px 6px rgba(0, 0, 0, 0.05);
    }

    &__header {
        display: flex;
        flex-direction: column;
        width: 100%;

        h1 {
            font-size: 18px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }

        h2 {
            font-size: 16px;
            color: $tealblue;
            font-weight: bold;
            line-height: 1.7;
        }
        h3 {
            font-size: 14px;
            color: #435970;
            font-weight: normal;
            line-height: 1.7;
            padding-bottom: 10px;
        }
        h4 {
            font-size: 12px;
            color: #a9b4bf;
            font-weight: normal;
            line-height: 1.7;
        }
    }
    &__badges {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;
        padding-top: 14px;
    }
    &__created {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 6px;
        span{
            font-size: 10px;
            color: rgba(67, 89, 112, 0.5);
        }
        h6{
            font-weight: bold;
            color: rgba(67, 89, 112, 0.5);
        }
    }
    &__badge {
        display: inline-block;
        //margin-top: 10px;
        margin-right: 20px;
        &:last-child {
            margin-right: 0;
        }
    }
}
