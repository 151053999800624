.dialog {
  &__input{
    width: 90%;
    height: 40px;
    padding: 0 20px;
    outline: none;
    border: 1px solid #849cb1;
    &::placeholder {
      color: #849cb1;
    }
    &:hover {
      border: 1px solid #435970;
    }
    &:focus {
      border: 1px solid #435970;
    }
  }
  &__content {
    padding: 24px 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 12px;
  }
  &__info{
    color: white;
    border-radius: 50%;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    width: 32px;
    height: 28px;
    background-color: #00a5ac;
  }
  &__text{
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #435970;
  }
}
